<template>
  <div class="dot-cards">
    <div class="steps-card">
      <div class="number"><h2 class="m-0">1</h2></div>
      <h5 class="m-3" v-html="text1" />
    </div>

    <div class="dots">
      <div />
    </div>

    <div class="steps-card">
      <div class="number"><h2 class="m-0">2</h2></div>
      <h5 class="m-3 mb-4" v-html="text2" />
    </div>

    <div class="dots">
      <div class="dot" />
    </div>
    <div class="steps-card">
      <div class="number"><h2 class="m-0">3</h2></div>
      <h5 class="m-3" v-html="text3" />
    </div>
  </div>
</template>

<script>
export default {
  name: "DotCards",
  props: {
    text1: String,
    text2: String,
    text3: String
  }
};
</script>

<style lang="scss">
@import "@/styles/common.scss";
.dot-cards {
  display: flex;
  align-items: stretch;
  justify-content: center;

  .steps-card {
    width: 350px;
    background-color: $color-grey-lighter5;
    border-radius: 10px;
    margin: 1rem;
    text-align: center;
    position: relative;
    h5 {
      line-height: 23px;
      font-size: 16px;
      color: $color-font-headings;
      margin-top: 2.5rem !important;
    }

    & > .number {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -20px;
      left: 50%;
      text-align: center;
      background-color: $color-pink-lighter7;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      transform: translate(-50%);

      & > h2 {
        color: white;
        font-weight: 900;
        font-size: 2rem;
        margin-top: -4px !important;
      }
    }
  }

  .dots {
    align-self: center;
    & > div {
      width: 20px;
      height: 20px;
      background-color: $color-pink-lighter7;
      border-radius: 50%;
    }
  }

  @media screen and (max-width: 991px) {
    display: block;

    .dots {
      display: none;
    }

    .steps-card {
      width: auto;
      padding: 0.5rem 0.5rem 0.5rem 2rem;
      text-align: left;

      & > .number {
        left: -20px !important;
        top: 50%;
        transform: translate(0%, -50%);
      }

      h5 {
        margin: 1rem !important;
      }
    }
  }
}
</style>
